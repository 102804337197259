<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                    <li class=" a"><router-link to="/index/hy">会员管理</router-link></li>
                     <li class="active a">会员统计</li>

                </div>
                 <div  class="item">  
                    <li class="a"><router-link to="/index2/xiaoxi">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i>消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible = true">市场总监<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                                        <el-dialog title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                             <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
        <div class="search"></div>
            <div class="content"  style="background:#f5f5f5;padding:10px 40px">
                     <ul class="content_ul">
                     <li style="background:#9351CC;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">累计会员</p>
                          <p style="font-size:30px;margin: 0">{{info.count_1}}</p>
                     </li>
                     <li style="background:#00ADD6;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">本月累计会员</p>
                        <p style="font-size:30px;margin: 0">{{info.count_2}}</p>
                     </li>
                     <li style="background:#F5C900;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">今日新增会员</p>
                          <p style="font-size:30px;margin: 0">{{info.count_3}}</p>
                     </li>
                      <li style="background:#49ADA8;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">代理商来源</p>
                        <p style="font-size:30px;margin: 0">{{info.count_4}}</p>
                     </li>
                     <li style="background:#80B547;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                     <p style="font-size:14px;margin:12px 0">琴行来源</p>
           <p style="font-size:30px;margin: 0">{{info.count_5}}</p>
                     </li>
                     <li style="background:#0093E0;color:#fff;height:90px;border-radius:10px;margin-right: 15px;">
                         <p style="font-size:14px;margin:12px 0">代理商 | 业务员来源</p>
         <p style="font-size:30px;margin: 0">{{info.count_6}}</p>
                     </li>
                     
                 </ul>
                    
                    
                   <div id="myChart1"  :style="{ height: '240px'}"></div>
                      <div id="myChart2"  :style="{ height: '240px'}"></div>
                 
            </div>
  </div>
</template>

<script>
export default {
    methods:{
               a11(){
               

                  this.$confirm('此操作将修改昵称, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
            this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken:localStorage.getItem('token'),
                   
                        },
                        data:{
                            "jobnumber": localStorage.getItem('jobnumber'),
                            "name": this.info.name
                        }
                        }) .then(res=>{
                        
                           
                          var cardNumObj = { arealist:res.data.data }
                           var cardNumObj1 = { yyzcurl:'' }
                          var assignObj =  Object.assign(this.info,cardNumObj);
                          var assignObj1 = Object.assign(assignObj,cardNumObj1)
                                        this.$axios({
                                        method: "post",
                                        url: "/s/backend/api/userupdate",
                                        params: {
                                        accessToken : localStorage.getItem('token')
                                        },
                                        data:assignObj1,
                                        }) .then(res=>{
                                            this.dialogFormVisible = false
                                            this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                        })
        .catch(function(){
           
        })  
                  
                        })
        .catch(function(){
           
        })  


        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });
      



 

      
       
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){

              this.$confirm('此操作将修改密码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                            }else{
                                    this.dialogFormVisible = false
                                    this.$message({
                                        message:res.data.msg,
                                        type: 'success'
                                            });
                            }
                            })
            .catch(function(){
            
            })  
            }

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });    
        },
       },
 data(){
        return{
c_list:[],
            info:[],
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],

            activeName: 'first',
    
                 tableData: [],
                    data1:[],
                    data2:[],
                 password:'',
                   password2:false,
                  password3:''
        }
        },
       
        mounted(){
               this.$axios.get("/s/backend/c_banner", {
    　  　params: { 'accessToken':localStorage.getItem('token')
        
    }
    })

        .then(res=>{
                this.info = res.data.result
     
        })
        .catch(function(){
           
        })


  this.$axios.get("/s/backend/c_active", {
        params: { 'accessToken':localStorage.getItem('token')
      }
        })

     .then(res=>{
           

            let arrs = []
            let arrs1 = []
           let arrs2 = []
           res.data.result.forEach(e => {
            arrs.push(e.date)
             arrs1.push(e.last)
            arrs2.push(e.now)
             })
        console.log(arrs)
          let myChartDrawer =  this.$echarts.init(document.getElementById('myChart1'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: '会员数量增长统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD','#0034FF'],
                    legend: {
                        data: ['本月新增', '上月新增'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '今日新增',
                            type: 'bar',
                            
                            data:arrs1
                        },
                        {
                            name: '累计总数',
                            type: 'bar',  barGap:'-100%',
                            data:arrs2
                        }
                      
                    ]
                };

                myChartDrawer.setOption(option);
 
        })
        .catch(function(){
           
        })

         this.$axios.get("/s/backend/c_rise", {
        params: { 'accessToken':localStorage.getItem('token')
      }
        })

      .then(res=>{
           

            let arrs = []
            let arrs1 = []
           let arrs2 = []
           res.data.result.forEach(e => {
            arrs.push(e.month)
             arrs1.push(e.value)
      
             })
        console.log(arrs)
          let myChartDrawer =  this.$echarts.init(document.getElementById('myChart2'))
       
                // 绘制条形图
                var option = {
                    title: {
                        text: '30天活跃度统计',
                        top: 5,
                        left: 'left'
                    },
                       color:['#4C73FD'],
                    legend: {
                        data: ['本月新增'],
                        top: 30
                    },  tooltip: {
                 trigger: "axis",  
            },
                    // X轴
                    xAxis: {
                    data: arrs,
                    splitLine:{
                    show:false,
                },
                    },
                    // Y轴
                    yAxis: {  splitLine:{
                    show:false,
                },},
                   
                    // 数据
                    series: [
                        {
                            name: '本月新增',
                            type: 'bar',
                            
                            data:arrs1
                        }
                       
                      
                    ]
                };

                myChartDrawer.setOption(option);
 
        })
        .catch(function(){
           
        })
  
  

              
        }
}
</script>

<style scoped>

/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/ .el-dialog{
        width: 25%;

    float: right;
  
}



 .content .content_ul{
            display: flex;
            justify-content: space-between;margin:30px 0
        }
         .content .content_ul li{
             width: 12%;padding-left: 15px;
         }


.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
</style>